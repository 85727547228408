.tag {
  padding: 2px 7px 2px 7px;
  text-align: center;
  color: #ffffff;
  border-radius: 10px;
  font-weight: 700;
}

.tag--draft {
  background: var(--ion-color-primary);
}

.tag--sent {
  background: #FFC107FF;
}

.tag--read {
  background: var(--ion-color-tertiary);
}

.tag--changes {
  background: #009688FF;
}

.tag--approved {
  background: var(--ion-color-success);
}

.tag--expired {
  background: var(--ion-color-danger);
}

.tag--denied {
  background: var(--ion-color-danger);
}

.tag--tbi {
  background: #FFC107FF;
}

.tag--invoiced {
  background: var(--ion-color-success);
}

.tag--paid {
  background: #FFC107FF;
}


// mail status
.tag--ok {
  background: var(--ion-color-primary);
}

.tag--changed, .tag--chsent {
  background: #FFC107FF;
}

.tag--bounced {
  background: var(--ion-color-danger);
}
