ion-toolbar {
  --background: var(--primary-color);
  --color: white;

  ion-back-button {
    --color: white;
  }

  ion-buttons {
    ion-button {
      --color: white;
    }
  }
}

.md ion-segment-button {
  --color-checked: white;
}

.ios ion-segment {
  width: 100%;
  margin: 0 16px;
}

.ios ion-segment-button {
  --background-checked: white;
  --color-checked: black;
  --indicator-color: white;
}

ion-content {
  --background: var(--catskill-white);
}

ion-card-header {
  padding-bottom: 0;
}

ion-card {
  margin-top: var(--spacing-m);
  margin-bottom: var(--spacing-m);
}

ion-item {
  cursor: pointer;
}

ion-list ion-list-header {
  ion-label {
    margin-top: 0;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 700;
    color: rgb(102,102,102);
  }
}

ion-loading.custom-loading {
  --background: transparent;
  --spinner-color: var(--primary-color);
  --backdrop-opacity: 0.3;
  color: var(--primary-color);
  box-shadow: none;

  ion-spinner {
    transform: scale(2);
  }
}

.loading-wrapper.sc-ion-loading-md {
  box-shadow: none;
  -webkit-box-shadow: none;
}

ion-input label {
  color: var(--Black-Base, #000);
  font-feature-settings: 'clig' off, 'liga' off;

  /* chip */
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.7px;
}

ion-input input::placeholder {
  font-size: 17px;
  color: var(--Medium-Light-theme-Base, #92949C);
}

// Ionic disabled/readonly inputs
.ion-time-input:disabled {
  opacity: 0.7;

  &::-webkit-calendar-picker-indicator {
    background: none;
    display: none;
  }
}